<template>
  <div class="download-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">用户壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">用户内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>文件下载</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
      </div>
      <div class="right">
        <el-button type="primary" @click="addNewDownload">添加新文件</el-button>
      </div>
    </div>
    <div class="page-content" v-if="dataList.length > 0">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="success" size="small" @click="editItem(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="no-list" v-else>
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取本会议的文件下载列表
    async getDataList () {
      const { data: res } = await this.$http.get('shell-download-list', { params: { mId: this.$route.params.mId } })
      if (res.status === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加新文件
    addNewDownload () {
      this.$router.push({ path: '/admin/apps/shell/content/download/' + this.$route.params.mId + '/add', query: { meetingName: this.$route.query.itemName } })
    },
    // 编辑
    editItem (id) {
      this.$router.push({ path: '/admin/apps/shell/content/download/' + this.$route.params.mId + '/edit/' + id, query: { meetingName: this.$route.query.itemName } })
    },
    // 删除
    async delItem (id) {
      const { data: res } = await this.$http.delete('/shell-download/' + id)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.content-header{
  text-align: right;
}
.search-bar{
  text-align: right;
  padding: 20px 0;
}
</style>
